





































































































































import { ButtonGroup, ConfirmModal, Scrollbar, Toggle } from '@/app/components';
import { useAxios } from '@/app/composable';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { SampleValues } from '../../components';
import WizardActions from '../../components/WizardActions.vue';
import { StatusCode } from '../../constants';
import { FieldConfiguration, Condition, Constraint, ConstraintStats } from './cleaning.types';
import CleaningConstraints from './CleaningConstraints.vue';
import CleaningFieldView from './CleaningFieldView.vue';

export default defineComponent({
    name: 'CleaningConfiguration',
    model: {
        prop: 'configuration',
    },
    components: {
        ButtonGroup,
        CleaningFieldView,
        CleaningConstraints,
        Scrollbar,
        WizardActions,
        Toggle,
        SampleValues,
        ConfirmModal,
    },
    props: {
        configuration: {
            type: Object,
            required: true,
        },
        sample: {
            type: Array,
            required: true,
        },
        previousStepSample: {
            type: Array,
            required: false,
        },
        processedSample: {
            type: Array,
            required: false,
        },
        mode: {
            type: String,
            default: null,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            required: true,
        },
        editMode: {
            type: String,
            required: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        stepStatus: {
            type: String,
            default: 'configuration',
        },
        problematicSampleConstraints: {
            type: Array as PropType<ConstraintStats[]>,
            default: () => [],
        },
        skipSampleRun: {
            type: Boolean,
            default: false,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        alternateNames: {
            type: Object,
            default: null,
        },
        canRunManyTimes: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit, root }) {
        const scrollUp = ref<boolean>(false);
        const constraintsRef = ref<any>(null);
        const activeFilters = ref<string[]>([]);
        const selectedFields = ref<any[]>([]);
        const { loading, cancel } = useAxios(true);
        const showAlternateNaming = ref<boolean>(false);
        const showConfirmModal = ref<boolean>(false);
        const selection = ref<{ field?: FieldConfiguration; multiple?: boolean }>({});

        const filters = computed(() => {
            const defaultFilters = [{ label: 'all', tooltip: 'Select fields of all datatypes' }];
            if (props.configuration) {
                return defaultFilters.concat(
                    R.uniq(
                        props.configuration.fields.map((field: any) => ({
                            label: field.type,
                            tooltip: `Select fields of ${field.type} datatype`,
                        })),
                    ),
                );
            }
            return defaultFilters;
        });

        const hasAlternateNames = computed(() => props.alternateNames && Object.keys(props.alternateNames).length);

        const saveChanges = () => {
            emit('save');
        };
        const setActiveFilter = (option: string) => {
            if (activeFilters.value.includes(option)) {
                // if filter is already active then make it inactive
                const index = activeFilters.value.indexOf(option);
                activeFilters.value.splice(index, 1);
                // remove selected fields based on the filter
                if (option === 'all') {
                    selectedFields.value = [];
                } else {
                    selectedFields.value = selectedFields.value.filter((field: any) => field.type !== option);
                }
            } else {
                // if filter is not active then make it active
                activeFilters.value.push(option);
                // select fields based on the active filter
                if (option === 'all') {
                    activeFilters.value = ['all'];
                    selectedFields.value = R.uniq([...selectedFields.value, ...props.configuration.fields]);
                } else {
                    const index = activeFilters.value.indexOf('all');
                    // if all fields were selected then clear selection
                    if (index !== -1) {
                        activeFilters.value.splice(index, 1);
                        selectedFields.value = [];
                    }
                    selectedFields.value = R.uniq([
                        ...selectedFields.value,
                        ...props.configuration.fields.filter((field: any) => field.type === option),
                    ]);
                }
            }
        };

        const updateSelected = (value: FieldConfiguration, multiple = false) => {
            const idx = selectedFields.value.findIndex((obj) => obj === value);
            if (multiple) {
                if (~idx) {
                    selectedFields.value.splice(idx, 1);
                } else {
                    selectedFields.value.push(value);
                }
            } else if (~idx && selectedFields.value.length === 1) {
                selectedFields.value.splice(0);
            } else {
                selectedFields.value = [value];
            }
        };

        const cancelModal = () => {
            showConfirmModal.value = false;
            selection.value = {};
        };

        const proceed = () => {
            emit('change-edit-mode', null);
            if (selection.value.field) {
                updateSelected(selection.value.field, selection.value.multiple);
            }
            cancelModal();
        };

        const checkMode = (value: FieldConfiguration, multiple = false) => {
            if (props.editMode) {
                showConfirmModal.value = true;
                selection.value = { field: value, multiple };
            } else {
                updateSelected(value, multiple);
            }
        };

        const clearSelection = () => {
            selectedFields.value.splice(0);
            activeFilters.value = [];
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                clearSelection();
            }
        };

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        const saveConstraints = () => {
            scrollUp.value = true;
            emit('changed');
            emit('change-edit-mode', null);
            scrollUp.value = false;
        };

        const problematicSampleFields = computed(() => {
            const problematicFields = {};
            if (props.problematicSampleConstraints.length && props.configuration) {
                for (const failedConstraint of props.problematicSampleConstraints) {
                    const failedConstraintId = (failedConstraint as any).id;
                    for (let i = 0; i < props.configuration.fields.length; i++) {
                        const field = props.configuration.fields[i];
                        if (!problematicFields[field.id]) problematicFields[field.id] = [];
                        for (let j = 0; j < props.configuration.fields[i].constraints.length; j++) {
                            const constraint = props.configuration.fields[i].constraints[j];
                            if (String(constraint.id) === String(failedConstraintId)) {
                                problematicFields[field.id].push(failedConstraint);
                                break;
                            }
                        }
                    }
                }
            }
            return problematicFields;
        });

        const updateSampleFailedConstraints = (constraintId: number) => {
            emit('constraint-updated', constraintId);
        };

        const advancedConstraints = computed(() => {
            const result: Constraint[] = [];
            props.configuration.fields.forEach((field: FieldConfiguration) => {
                field.constraints?.forEach((constraint: Constraint) => {
                    if (constraint.structure !== undefined) result.push(constraint);
                });
            });
            return result;
        });

        const getInvolvedFieldsCount = (constraints: Constraint[] | Condition[], counts: any) => {
            constraints.forEach((c: any) => {
                if (c.structure !== undefined) {
                    if (c.structure?.conditions.length) getInvolvedFieldsCount(c.structure.conditions, counts);
                } else if (c.conditions !== undefined) {
                    if (c.conditions?.length) getInvolvedFieldsCount(c.conditions, counts);
                } else if (c.fieldName) {
                    if (!counts[c.fieldName]) counts[c.fieldName] = 0;
                    counts[c.fieldName]++;
                }
            });
        };

        const involvedFieldsCount = computed(() => {
            const counts = {};
            getInvolvedFieldsCount(advancedConstraints.value, counts);
            return counts;
        });

        return {
            constraintsRef,
            filters,
            activeFilters,
            cancel,
            clearSelection,
            setActiveFilter,
            loading,
            saveChanges,
            selectedFields,
            updateSelected,
            saveConstraints,
            emit,
            scrollUp,
            StatusCode,
            problematicSampleFields,
            updateSampleFailedConstraints,
            hasAlternateNames,
            showAlternateNaming,
            involvedFieldsCount,
            showConfirmModal,
            checkMode,
            proceed,
            cancelModal,
        };
    },
});
